import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services
import http from "../../../services/httpService";

// Import Utils
import { getRegionList } from "../../../utils/handleRegions";
import { handleReactGAEvents } from "../../../utils/handleReactGAEvents";

// Import Components
import LNFloatingInput from "../../common/LNFloatingInput/LNFloatingInput";
import LNRadioInput from "../../common/LNRadioInput/LNRadioInput";
import LNSelect from "../../common/LNSelect/LNSelect";
import LNDatePicker from "../../common/LNDatePicker/LNDatePicker";
import LNUpload from "../../common/LNUpload/LNUpload";
import LNError from "../../common/LNError/LNError";

// Import CSS
import "./lendingLicense.css";

class LendingLicense extends Component {
	state = {
		data: {
			have_license: "",
			state: "",
			type: "",
			file: "",
			number: "",
			issue_date: "",
			expiry_date: "",
		},
		error: false,
		errorMessages: "",
		pressCount:0
	};

	componentDidMount() {
		const loadCount = async () => {
		  try {
			const storedCount = await localStorage.getItem('pressCount');
			if (storedCount) {
			  console.log("parseInt(storedCount)",storedCount);
			  this.setState({ pressCount: parseInt(storedCount, 10) });
			}
		  } catch (error) {
			console.error('Error loading press count:', error);
		  }
		};
		loadCount();
	  }
	render() {
		const { data, error, errorMessages } = this.state;

		// Validations
		const ValidationSchema = Yup.object().shape({
			have_license: Yup.string().required("Please choose an option"),
			state: Yup.string().when("have_license", {
				is: "1",
				then: Yup.string().required("Required field"),
			}),
			type: Yup.string().when("have_license", {
				is: "1",
				then: Yup.string().required("Please choose license type"),
			}),
			file: Yup.string().when("have_license", {
				is: "1",
				then: Yup.string().required("File is required"),
			}),
			number: Yup.string().when("have_license", {
				is: "1",
				then: Yup.string().max(255, "Too Long!").required("Required field"),
			}),
			issue_date: Yup.string().when("have_license", {
				is: "1",
				then: Yup.string()
					.test("issue_date", "Please verify your input is correct.", (value) => {
						if (value === undefined) {
							return true;
						}
						return moment().diff(moment(new Date(value)), "days") > 0;
					})
					.required("Required field"),
			}),
			expiry_date: Yup.string().when("have_license", {
				is: "1",
				then: Yup.string()
					.test("expiry_date", "Please verify your input is correct.", (value) => {
						if (value === undefined) {
							return true;
						}
						return moment().diff(moment(new Date(value)), "days") < 1;
					})
					.required("Required field"),
			}),
		});

		// Render
		return (
			<div className="signup-process-form lending-license">
				{/* header */}
				<div className="form-header mb-5">
					<div className="main-form-label">Start lending with Lenme Enterprise</div>
					<div>License and registration, please.</div>
				</div>

				{/* Form */}
				<Formik
					initialValues={data}
					validationSchema={ValidationSchema}
					onSubmit={(values, { setSubmitting, setErrors }) => {
						if (values.have_license === "0") {
							setSubmitting(false);
							this.props.incrementfunction();
							return;
						}

						setSubmitting(true);
						const postData = {
							number: values.number,
							type: values.type,
							state_of_issuance: values.state,
							issue_date: values.issue_date,
							expiry_date: values.expiry_date,
							file: values.file,
						};

						if (values.middle_name !== "") {
							postData.middle_name = values.middle_name;
						}

						http.post(`enterprise/v${process.env.REACT_APP_API_VERSION}/license/`, postData)
							.then((res) => {
								this.setState({
									error: false,
								});
								setSubmitting(false);
								this.props.incrementfunction();

								// Send GA event
								handleReactGAEvents("Sign_up_step_5");
							})
							.catch((error) => {
								console.log("Error: ", error);
								this.setState({
									error: true,
									errorMessages: error.response.data,
								});
								setErrors(this.state.errorMessages);
								setSubmitting(false);
							});
					}}>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						setSubmitting,
					}) => (
						<form onSubmit={handleSubmit} spellCheck={false}>
							<div className="inputs-wrapper">
								{error && <LNError text={errorMessages} />}
								{touched?.have_license && errors?.have_license && (
									<LNError text={errors.have_license} />
								)}
								{touched?.type && errors?.type && <LNError text={errors.type} />}

								<span className="text-muted d-block mb-3">
									Do you have a lending or broker license?
								</span>

								<div className="mb-2">
									<div className="mb-2">
										<LNRadioInput
											name="have_license"
											label="Yes"
											value="1"
											onChange={handleChange}
										/>
									</div>

									<div className="mb-2">
										<div className="mb-2">
											<LNRadioInput
												name="have_license"
												label="No"
												value="0"
												onChange={handleChange}
											/>
										</div>
									</div>

									{values.have_license === "1" && (
										<div className="mt-3">
											<LNSelect
												name="state"
												size="lg"
												value={values.state}
												onChange={handleChange}
												onBlur={handleBlur}
												defaultOption={{ name: "State of issuance", value: "" }}
												options={getRegionList("US")}
												error={touched.state && errors.state ? `* ${errors.state}` : ""}
											/>

											<div className="mt-3">
												<span className="text-muted d-block mb-3">License type</span>

												<div className="mb-2">
													<div className="mb-2">
														<LNRadioInput
															name="type"
															label="Lending License"
															value="lending"
															onChange={handleChange}
														/>
													</div>

													{values.type === "lending" && (
														<LNUpload
															document_type="lending"
															change_function={(file_id) => {
																setFieldValue("file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
															error={
																touched.file && errors.file ? `* ${errors.file}` : ""
															}
														/>
													)}
												</div>

												<div className="mb-2">
													<div className="mb-2">
														<LNRadioInput
															name="type"
															label="Broker License"
															value="broker"
															onChange={handleChange}
														/>
													</div>

													{values.type === "broker" && (
														<LNUpload
															document_type="broker"
															change_function={(file_id) => {
																setFieldValue("file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
															error={
																touched.file && errors.file ? `* ${errors.file}` : ""
															}
														/>
													)}
												</div>
											</div>

											{values.type && (
												<>
													<LNFloatingInput
														size="lg"
														type="text"
														name="number"
														value={values.number}
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="License Number"
														error={
															touched.number && errors.number ? `* ${errors.number}` : ""
														}
													/>

													<LNDatePicker
														size="lg"
														optional={false}
														name="issue_date"
														value={values.issue_date}
														placeholder="Issue Date"
														dateFormat="MM/dd/yyyy"
														onChange={(date) =>
															setFieldValue(
																"issue_date",
																moment(date).format("YYYY-MM-DD")
															)
														}
														error={
															touched.issue_date && errors.issue_date
																? `* ${errors.issue_date}`
																: ""
														}
													/>

													<LNDatePicker
														size="lg"
														optional={false}
														name="expiry_date"
														value={values.expiry_date}
														placeholder="Expiry Date"
														dateFormat="MM/dd/yyyy"
														onChange={(date) =>
															setFieldValue(
																"expiry_date",
																moment(date).format("YYYY-MM-DD")
															)
														}
														error={
															touched.expiry_date && errors.expiry_date
																? `* ${errors.expiry_date}`
																: ""
														}
													/>
												</>
											)}
										</div>
									)}
								</div>
							</div>

							<div className="d-flex mt-5 buttons-wrapper">
								<div className="mr-3">
									<Link 
										onClick={async()=>{
											const newCount = this.state.pressCount + 1;
											this.setState({ pressCount: newCount });
											try {
											await localStorage.setItem('pressCount', newCount.toString());
												console.log('Press count saved to storage:', newCount);
											} catch (error) {
												console.error('Error saving press count:', error);
											}
										}}
										className="btn btn-white btn-lg btn-block" to="/">
										Save and Exit
									</Link>
								</div>

								<div className="flex-grow-1">
									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Next
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		);
	}
}

// Prop Types
LendingLicense.propTypes = {
	incrementfunction: PropTypes.func.isRequired,
};

export default LendingLicense;
