// src/ReCaptcha.js
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = ({onChange}) => {

  const recaptchaRef = React.createRef();  
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <div>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          ref={recaptchaRef}
          onChange={onChange}
          style={{marginLeft:40}}
        />
      </div>
  );
};

export default ReCaptcha;
