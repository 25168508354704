import React from "react";
import PropTypes from "prop-types";

// Images
import AppleIcon from "../../../img/icons/apple-pay.svg";

// CSS
import "./applePayButton.css";

function ApplePayButton({ loan_request_id }) {
	const allowed = false;

	// Helper Methods
	const handleClick = () => {};

	// Check if allowed
	if (!allowed) return null;

	// Render
	return (
		<button onClick={handleClick} className="btn btn-black btn-block btn-lg" disabled>
			<img src={AppleIcon} alt="Apple Pay" />
		</button>
	);
}

// Prop Types
ApplePayButton.propTypes = {
	loan_request_id: PropTypes.number.isRequired,
};

export default ApplePayButton;
