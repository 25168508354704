import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
// import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import auth2 from "../../services/authService";
// Import Services
import auth from "../../services/authService";
import { register } from "../../services/signupService";
import LNError from "../common/LNError/LNError";
// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import CenterImageSVG from "../../img/static/logo.svg"

// Import Images
import Logo from "../../img/assets/logo.svg";

// Import CSS
import "./signUpPage.css";

class SignUpPage extends Component {
	state = {
		loading: true,
		data: {
			companyName: "",
			email: "",
			password: "",
			confirmPassword: "",
		},
		error: false,
		errorMessage: "",
	};

	// Get Account
	populateAccount() {
		const account = auth.getCurrentAccount();
		this.setState({ account });
	}

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		setTimeout(() => {
		// Get account
		this.populateAccount();

		// End loading
		this.handleLoading();
		}, 1000);
	
	}

	// Render
	render() {
		const { loading, account, data } = this.state;
		const accessTokenKey = "accessToken";

		// Check if account
		if (account) return <Navigate to="/" />;

		// Validations
		const ValidationSchema = Yup.object().shape({
			// companyName: Yup.string()
			// 	.min(1, "Too Short!")
			// 	.max(255, "Too Long!")
			// 	.required("Required field"),
			email: Yup.string()
				.email("Please enter a valid email address.")
				.max(255, "Too Long!")
				.required("Required field"),
			password: Yup.string()
				.min(8, "Password must be atleast 8 characters.")
				.max(255, "Too Long!")
				.matches(
					/(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W)/,
					"Password must contain atleast one uppercase letter, one lowercase letter, one number and one special character."
				)
				.required("Required field."),
			confirmPassword: Yup.string()
				.min(1, "Too Short!")
				.max(255, "Too Long!")
				.oneOf([Yup.ref("password"), null], "Passwords must match")
				.required("Required field"),
		});

		// Check if loading
		if (loading) return <div>Loading...</div>;

		const googleIcon  =  require('../../img/static/Google.png');
		const centerImage = require('../../img/static/center_Image.png');

	const handleGoogleSignUp = async () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();
		provider.addScope('profile');
		provider.addScope('email');
		provider.addScope('openid');
		await signInWithPopup(auth, provider).then(async(result)=>{
			const user = result.user;
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const token = credential.idToken;
			console.log("token after update",token);
			// await auth2.socialLogin(user?.accessToken).then((res)=>{
				setTimeout(async() => {
					await localStorage.removeItem('pressCount')
					// window.location = "/";
					window.location = "/signup-process";
				}, 1000)
			}).catch((err) => {
				console.error("error",err)
			}).catch((error)=>{
			console.log("Error_In_signInWithPopup_Signup", error);
			window.location.reload()
			
		})	
		}
		// Render
		return (
			<div className="bg-landing" style={{
				display: 'flex',
				flex:  1,
				backgroundColor: '#F8F9FF',
				flexDirection:"row",
				alignItems:"center",
				justifyContent:"center"
				}}>
				<div className="bg-landing" style={{
					display: 'flex',
					flex: 0.45,
					alignItems:"center",
					justifyContent:"center",
					backgroundColor: 'white',
				}}>
					<div style={{
						maxWidth:388,
						display: 'flex',
						flex: 1,
						backgroundColor: 'white',
						justifyContent:"center",
						flexDirection:"column",
						margin:20
					}}>
						<div style={{marginBottom:10}} className="mt-4">
							<img style={{height:36, width:36}} src={Logo} alt="Logo" />
						</div>								
						<div style={{
							color:"#0C1421",
							fontSize:32,
							marginTop:10,
							marginBottom:10,
							fontWeight:"600"
						}}>
							Welcome to Lenme Enterprise
						</div>
						{this.state.error && <LNError text={this.state.errorMessage} />}
						<Formik
							initialValues={data}
							validationSchema={ValidationSchema}
							onSubmit={async (values, { setSubmitting, setErrors }) => {
								setSubmitting(true);
								this.setState({error: false})
								const auth = getAuth();
								await createUserWithEmailAndPassword(auth, values?.email , values?.password).then(async (response)=>{
									localStorage.setItem(accessTokenKey,response?.user?.accessToken)

									console.log("response firebase sign up", response?.user?.accessToken);
									setTimeout(async() => {
										localStorage.removeItem('pressCount')
										window.location = "/signup-process";
										return false
									}, 1000)
									// }
									// await auth2.socialLogin(response?.user?.accessToken).then(()=>{
									// 	setTimeout(async() => {
									// 		await localStorage.removeItem('pressCount')
									// 		window.location = "/signup-process";
									// 	}, 1000)
									// 	// setSubmitting(false);

									// }).catch((error) => {
									// 	setSubmitting(false);
									// 	console.error("socialLogin_Error",error);
									// })
									})
								.catch((error)=>{
									const errorCode = error.code;
									console.log("errorCode",errorCode);
									if(errorCode == "auth/invalid-credential"){
										this.setState({
											error:true,
											errorMessage: "Sorry, we couldn't find an account with that email or password. Please try again"
										})
									}
									if(errorCode === 'auth/email-already-in-use'){
										this.setState({
											error:true,
											errorMessage: 'We\'re sorry. An account with that email address already exists. please login instead.'
										})
									}
									else{
										this.setState({
											error:true,
											errorMessage:error.message
										})
										
									}
									setSubmitting(false);
								})
							}}>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
							}) => (
								<form onSubmit={handleSubmit}>
									<div style={{
										marginTop:10,
										marginBottom:10,
										fontSize:16,
										color:"#0C1421",
									}}>
										Company email
									</div>
									{/* <LNFloatingInput
										size="lg"
										type="text"
										optional={false}
										name="companyName"
										value={values.companyName}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="Company Name"
										error={
											touched.companyName && errors.companyName
												? `* ${errors.companyName}`
												: ""
										}
									/> */}
									<LNFloatingInput
										style={{height:48}}
										size="lg"
										type="email"
										optional={false}
										name="email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="Example@email.com"
										error={touched.email && errors.email ? `* ${errors.email}` : ""}
									/>
									<div style={{
										marginTop:10,
										marginBottom:10,
										fontSize:16,
										color:"#0C1421",
									}}>
										Password
									</div>
									<LNFloatingInput
										style={{height:48}}
										size="lg"
										type="password"
										optional={false}
										name="password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="At least 8 characters"
										error={
											touched.password && errors.password
												? `* ${errors.password}`
												: ""
										}
									/>
									<div style={{
										marginTop:10,
										marginBottom:10,
										fontSize:16,
										color:"#0C1421",
									}}>
										Confirm password
									</div>
									<LNFloatingInput
										style={{height:48}}
										size="lg"
										type="password"
										optional={false}
										name="confirmPassword"
										value={values.confirmPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="At least 8 characters"
										error={
											touched.confirmPassword && errors.confirmPassword
												? `* ${errors.confirmPassword}`
												: ""
										}
									/>
									<button
										style={{
											color:"#FFFFFF",
											fontSize:16,
											fontWeight:"100",
											borderRadius:12,
											}} 
										className="btn btn-primary btn-lg btn-block mt-4"
										type="submit"
										disabled={isSubmitting}
										onClick={handleSubmit}>
										Next
									</button>
								</form>
							)}
						</Formik>
						<div style={{fontSize:14, marginTop:16, maxWidth:346, textAlign:"center",marginLeft:20, marginBottom:10}}>
							By clicking "Next" I agree to Lenme's{" "}
							<a
								target="_blank"
								className="text-dark"
								rel="noopener noreferrer"
								href="https://lenmeenterprise.com/terms-of-use/">
								Terms of Service
							</a>{" "}
							and{" "}
							<a
								target="_blank"
								className="text-dark"
								rel="noopener noreferrer"
								href="https://lenmeenterprise.com/privacy-policy/">
								Privacy Policy
							</a>
							.
						</div>
						<div style={{
							display:"flex",
							alignItems:"center",
							justifyContent:"center",
							marginBottom:24	
							}}>
							<div style={{
								width:168.5,
								backgroundColor:"#CFDFE2",
								height:1,
								borderRadius:10
							}}/>			
							<p className="mt-3" style={{marginLeft:20,marginRight:20}}>Or</p>
							<div style={{
								width:168.5,
								backgroundColor:"#CFDFE2",
								height:1,
								borderRadius:10
							}}/>
						</div>
						<div
							style={{
								color:"#FFFFFF",
								fontSize:16,
								fontWeight:"100",
								borderRadius:12,
								marginBottom:40,
							}} 
							className="btn btn-black btn-lg" 
							onClick={handleGoogleSignUp}>
								<img style={{alignSelf:"center", height:20, marginRight:10}} src={googleIcon} alt="Logo" />		
								Sign up with Google
						</div>
						<div>
						<div style={{
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "center",
							}}>
							<p style={{
								color:"#313957",
								fontSize:16,
								fontVariant:"revert"
							}}>
								Already have account?
							</p>
							<Link to="/login" 
								style={{
								color:"#1E4AE9",
								fontSize:16,
								marginLeft:5
							}}>
								Sign in
							</Link>
						</div>	
					</div>
					</div>
				</div>
				<div style={{flex:0.55,justifyContent:"center", display:"flex"}}>
					<div className="col-lg-6 d-none d-sm-flex" style={{justifyContent:"center",alignItems:"center"}}>
						<img src={CenterImageSVG} alt="Logo" />		
					</div>
				</div>
			</div>	
		);
	}
}

// Prop Types
SignUpPage.propTypes = {};

export default SignUpPage;
