import React from "react";
import PropTypes from "prop-types";

// Import CSS
import "./LNError.css";

const LNError = ({ text }) => {
	// Helper Method
	const returnError = () => {
		// String
		if (typeof text === "string") {
			return text;
		}

		// Object
		if (typeof text === "object") {
			// Array
			if (Array.isArray(text)) {
				return text[0];
			}

			// Object
			if (!Array.isArray(text)) {
				return Object.values(text)[0];
			}
		}
	};

	// Render
	return <div className="alert alert-danger">{returnError()}</div>;
};

// Prop Types
LNError.propTypes = {
	text: PropTypes.any.isRequired,
};

export default LNError;
