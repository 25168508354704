import React, { Component } from "react";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
// Import Services
import LNError from "../common/LNError/LNError";
// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
// Import Images
import Logo from "../../img/assets/logo.svg";
// Import CSS
import "./forgotPasswordPage.css";
import CenterImageSVG from "../../img/static/logo.svg"

class ForgotPassword extends Component {
	state = {
		loading: true,
		data: {
			email: "",
		},
		error: false,
		errorMessage: "",
		success: false,
	};

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		// End loading
		this.handleLoading();
	}

	// Helper Methods

	// Render
	render() {
		const { loading, data, success } = this.state;

		// Validations
		const ValidationSchema = Yup.object().shape({
			email: Yup.string()
				.email("Please enter a valid email address.")
				.max(255, "Too Long!")
				.required("Required field"),
		});

		// Check if loading
		if (loading) return <div>Loading...</div>;
		
		const centerImage = require('../../img/static/center_Image.png');
		const successImage = require('../../img/static/successLogo.png')
		// Render
		return (			
			<div className="bg-landing" style={{
				display: 'flex',
				flex: 1,
				backgroundColor: '#F8F9FF',
				flexDirection:"row",
				justifyContent:"center"
			  }}>
				<div className="bg-landing" style={{
					display: 'flex',
					flex: 0.45,
					alignItems:'center',
					justifyContent:"center",
					backgroundColor: 'white',
			  	}}>
				<div style={{
					maxWidth:388,
					display: 'flex',
					flex: 1,
					backgroundColor: 'white',
					justifyContent:"center",
					flexDirection:"column",
					margin:20,
					marginRight:10,
					marginTop:30
				}}>
					<div style={{marginTop:30,marginBottom:24}}>
						<img style={{width:36, height:36}} src={Logo} alt="Logo" />
					</div>
					{success ? (
						<>
						<div style={{
							color:"#0C1421",
							fontSize:32,
							fontWeight:"600"
						}}>
							Email has sent!
						</div>
						<div style={{
							color:"#313957",
							fontSize:18,
							marginTop:20,
							marginBottom:40
							}}>
							We’ve sent over instructions to reset your password.					
						</div>
						<Link onClick={()=>this.setState({success:false})}
							style={{
								color:"#FFFFFF",
								fontSize:16,
								borderRadius:12,
								marginBottom:300
							}}  
							to="/" className="btn btn-primary btn-lg btn-block">
								Sign in
						</Link>
						</>
						)
						:
						(
						<>
						<div style={{
							color:"#0C1421",
							fontSize:32,
							marginBottom:24,
							fontWeight:"600"
						}}>
							Password reset
						</div>
						<div style={{
							color:"#313957",
							fontSize:18,
							marginBottom:70
							}}>
							Enter the email you used to create your account so we can send you instructions on how to reset your password.
						</div>
						{this.state.error && <LNError text={this.state.errorMessage} />}
							<Formik
								initialValues={data}
								validationSchema={ValidationSchema}
								onSubmit={async (values, { setSubmitting, setErrors }) => {
									const auth = getAuth();
									await sendPasswordResetEmail(auth, values.email).then(async (response)=>{
										this.setState({success:true})
										console.log("response firebase", response?.user?.accessToken)
									})
									.catch((error)=>{
										const errorCode = error.code;
										console.log("errorCode",errorCode);
											this.setState({
												error:true,
												errorMessage:error.message
											})
											setErrors(this.state.errorMessage);
											setSubmitting(false);
										
										})
									setSubmitting(true);
								}}>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
								}) => (
								<form onSubmit={handleSubmit}>
									<div style={{
										marginTop:10,
										marginBottom:10,
										fontSize:16,
										color:"#0C1421",
									}}>
										Email
									</div>
									<LNFloatingInput
										style={{height:48}}
										size="lg"
										type="email"
										optional={false}
										name="email"
										value={values?.email}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="Example@email.com"
										error={
											touched?.email && errors?.email ? `* ${errors?.email}` : ""
										}
									/>
									<button style={{
										color:"#FFFFFF",
										fontSize:16,
										fontWeight:"100",
										borderRadius:12,
										}} 
										className="btn btn-primary btn-lg btn-block mt-4"
										type="submit"
										disabled={isSubmitting}
										onClick={handleSubmit}>
										Send
									</button>
								</form>
								)}
							</Formik>
							<div style={{
								display:"flex",
								alignItems:"center",
								alignSelf:"center",
								marginTop:5,
								justifyContent:"center",
								marginBottom:12
							}}>
							<div style={{width:190}}/>
							<div style={{width:190}}/></div>
							</>
						)}
						<div style={{
							display: "flex",
							justifyContent: "center",
							marginTop:success?100:157								
						}}>
							<p style={{
								color:"#313957",
								fontSize:18
							}}>
								Don't you have an account?
							</p>
							<Link to="/signup" 
								style={{
								color:"#1E4AE9",
								fontSize:18,
								marginLeft:5
								}}>
								Sign up
							</Link>
						</div>
					</div>
				</div>
				<div style={{flex:0.55,justifyContent:"center", display:"flex"}}>
					<div className="col-lg-6 d-none d-sm-flex" style={{justifyContent:"center",alignItems:"center"}}>
						<img src={CenterImageSVG} alt="Logo" />
					</div>
				</div>
			</div>		
				);
			}
		}
export default ForgotPassword;
