import React, { Component } from "react";
import PropTypes from "prop-types";

// Import Services
import http from "../../../services/httpService";

// Import Components
import ReactDropzone from "react-dropzone";

// Import Images
import uploadCancel from "../../../img/assets/upload_cancel.svg";

// Import CSS
import "./LNUpload.css";

class LNUpload extends Component {
	state = {
		uploadProgress: 0,
		fileName: "",
	};

	// Check if file uploaded
	populateFile() {
		if (this.props.existing_file !== "" && this.props.existing_file !== undefined) {
			this.setState({ fileName: this.props.existing_file, uploadProgress: 100 });
		}
	}

	componentDidMount() {
		// Check file exist
		this.populateFile();
	}

	// Helper Methods
	handleDrop = async (file) => {
		this.props.set_loading(true);
		const formData = new FormData();
		formData.append("file", file[0]);
		formData.append("document_side", "front");
		formData.append("document_type", this.props.document_type);
		this.setState({ fileName: file[0].name });
		try {
			await http
				.post(`enterprise/v${process.env.REACT_APP_API_VERSION}/enterprise_file_upload/`, formData, {
					headers: {
						"X-Requested-With": "XMLHttpRequest",
					},
					onUploadProgress: (progressEvent) => {
						this.setState({
							uploadProgress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
						});
					},
				})
				.then((response) => {
					if (this.props.change_function) {
						this.props.change_function(response.data.id);
					}
					this.props.set_loading(false);
				})
		} catch (error) {
			console.error("handleDrop_Error ", error);
			this.props.set_loading(false);
		}
	};

	// Render
	render() {
		return (
			<>
				<div className="upload-box-container">
					<ReactDropzone 
						accept={{
							'application/pdf': ['.pdf'],
							'image/png': ['.png'],
							'image/jpeg': ['.jpg', '.jpeg'],
							'image/avif': ['.avif'],
						}} 
						onError={(err) => console.log('ReactDropzone_onError ', err.message)} 
						onDrop={this.handleDrop} 
						multiple={false}
					>
						{({ getRootProps, getInputProps }) => (
							<div className="form-group">
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<div
										className="upload-box"
										style={{
											borderStyle: `${this.state.uploadProgress === 100 ? "solid" : "dashed"}`,
										}}>
										<div className="upload-box-text">
											{this.state.fileName && (
												<>
													<span>{this.state.fileName.toString().toLowerCase()}</span>

													{this.state.uploadProgress < 100 && (
														<span className="percentage-value">
															{this.state.uploadProgress}%
														</span>
													)}
												</>
											)}

											{!this.state.fileName && "Drag & drop or Browse file"}
										</div>

										<div
											style={{ width: `${this.state.uploadProgress}%` }}
											className="upload-box-progress"></div>
									</div>
								</div>
							</div>
						)}
					</ReactDropzone>

					{/* Remove Button */}
					{this.state.uploadProgress === 100 && (
						<div
							className="cancel-upload-icon"
							onClick={() => {
								this.setState({ uploadProgress: 0, fileName: "" });
							}}>
							<img src={uploadCancel} alt="x" />
						</div>
					)}
				</div>

				{this.props?.error && <div className="invalid-feedback d-block">{this.props?.error}</div>}
			</>
		);
	}
}

// Prop Types
LNUpload.propTypes = {
	change_function: PropTypes.func,
	set_loading: PropTypes.func,
};

export default LNUpload;
