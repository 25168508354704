import axios from "axios";
import {log as sentry_log} from "./logService";
import refreshTokenInterceptor from "./refreshTokenService";
import  auth  from './firebaseInitExtended'; // Import Firebase auth
import { getIdToken } from "firebase/auth";
import { initializeApp } from 'firebase/app';

import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check';
// Keys

const accessTokenKey = "accessToken";
let isRefreshing = false;
let failedQueue = [];

const StagingConfig = {
	apiKey: "AIzaSyCII_kqlxl_PAcbfAbBLFsWBP9dfvLLe3M",
	authDomain: "lenmo-2a623.firebaseapp.com",
	databaseURL: "https://lenmo-2a623.firebaseio.com",
	projectId: "lenmo-2a623",
	storageBucket: "lenmo-2a623.appspot.com",
	messagingSenderId: "478466344575",
	appId: "1:478466344575:web:b82ce9761adb7c730fe14a",
	measurementId: "G-E8YN7YEKT7",
	webClientId: "478466344575-jl1lugcoubstdf5li74vsv5egqjupf35.apps.googleusercontent.com",

};

const app = initializeApp(StagingConfig);

const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider('6LdeUoAqAAAAAJSHw0xP1_wnMDqQ0a_bf2UneSul'),
	isTokenAutoRefreshEnabled: true 
	
});

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
	  if (error) {
		prom.reject(error);
	  } else {
		prom.resolve(token);
	  }
	});
  
	failedQueue = [];
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Interceptors Configuration
// axios.interceptors.request.use(async request => {
// 	const user = auth.auth.currentUser;
// 	console.log("user_user_user_user", user);
//     if (!user) return request;
// 	console.log("user_user_user_user", user);
//     try {
//         const accessToken = await user.getIdToken(true);
// 		console.log("accessToken", accessToken);
//         request.headers.Authorization = `Bearer ${accessToken}`;
//     } catch (err) {
//         console.error('Error fetching token from Firebase:', err);
//         return Promise.reject({ refreshTokenError: err });
//     }
// 	console.log("Request Headers:", request.headers);

//     return request;
// }, 
// (error) => {
//     return Promise.reject(error);
// });

const getFirebaseToken = async () => {

	const firebaseCurrentUser = auth.auth.currentUser;

	if (!firebaseCurrentUser) {
		console.log("Not_Firebase_User");
		firebaseTokenLogout();
		return false;
	}

	const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
	const maxAttempts = 5;
	let attempts = 0;

	while (attempts < maxAttempts) {
		try {
			const token = await firebaseCurrentUser.getIdToken(true);
			if (token) {
				return token; 
			} else {
				console.log("Failed_To_Get_Token");
				firebaseTokenLogout()
				return false
			}
		} catch (error) {
			if (error.message.includes('auth/network-request-failed')) {
				attempts++;
				if (attempts < maxAttempts) {
					console.log(`Network error, retrying (${attempts}/${maxAttempts})...`);
					const delayTime = 2000 + (attempts - 1) * 1000;
					await delay(delayTime);
				} else {
					console.log("Failed to get token after multiple retries due to network issue");
					firebaseTokenLogout();
					return false;
				}
			} else {
				console.error("Error getting token:", error);
				firebaseTokenLogout();
				return false;
			}
		}
	}
}


axios.interceptors.request.use(async request => {

    const user =  auth.auth.currentUser;

    if (user) {
		const accessToken = await getFirebaseToken()
		const appCheckToken = await getToken(appCheck);
		localStorage.setItem(accessTokenKey, accessToken);  
		request.headers.Authorization = `Bearer ${accessToken}`;
		request.headers['X-Ac-Token'] = `Bearer ${appCheckToken?.token}`;
		}
	
    console.log("Request Headers:", request.headers);

    return request;
}, 
(error) => {
	const _error = error ?? 'axios_request_rejected_error_catch_Err';
    return Promise.reject(_error);
});


function firebaseTokenLogout() {
	localStorage.removeItem(accessTokenKey);
	window.location = "/login";
}

axios.interceptors.response.use(null, error => {
	// if (error.response.status === 401) window.location.reload();
	let rejected_error = error;

	const expectedError = error?.response && error.response?.status >= 400 && error.response?.status < 500;
	if (error.response?.status === 403){
		console.error("axios.interceptors.response_error", error.response);
		firebaseTokenLogout()
	} 	

	console.log('axios.interceptors.response ', error)
	// if(error.response?.data?.detail && error.response?.data?.detail !== "Authentication credentials were not provided."){
	// 	alert(error.response?.data?.detail)
	// }

	if (!expectedError) {

		sentry_log(error);
		alert("An unexpected error occurrred.");
	}
	
	const _error = rejected_error ?? 'axios_response_rejected_error_catch_Err';
	return Promise.reject(_error);
});

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
};
