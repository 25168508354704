import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCompanyData } from "../../redux";
import store from "../../redux/store";
import PropTypes from "prop-types";

// Import Services
import signupService from "../../services/signupService";

// Import Components
import PersonalInfo from "../forms/personalInfo/personalInfo";
import CompanyInfo from "../forms/companyInfo/companyInfo";
import CompanyDetails from "../forms/companyDetails/companyDetails";
import OwnershipInfo from "../forms/ownershipInfo/ownershipInfo";
import LendingLicense from "../forms/lendingLicense/lendingLicense";
import LinkBank from "../forms/linkBank/linkBank";
import Header from "../header/header";

// Import Images
import activeDot from "../../img/assets/active_dot.svg";
import inactiveDot from "../../img/assets/inactive_dot.svg";
import tick from "../../img/assets/tick.svg";

// Import CSS
import "./signUpProcess.css";
import { logFirebaseEvent } from "../../services/firebase-analytics";

class SignUpProcess extends Component {
	state = {
		loading: true,
		currentStep: 1, //Default start is 1, changed for testing
		dotVisibility: true,
		sole: false,
	};

	// Populate the Current Step
	async populateTheCurrentStep() {
		try {
			const { data } = await signupService.getRegistrationStep();
			console.log('signupService_getRegistrationStep_data ', data)
			this.setState({ currentStep: data.registration_step === 0 ? 1 : data.registration_step });
			return data.registration_step;
		} catch (ex) {
			console.error(ex);
			return null;
		}
	}

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		// Populate the current step
		this.timer = setTimeout(async () => {
			const currentStep = await this.populateTheCurrentStep();
			console.log('componentDidMount_currentStep ', currentStep);
			if (currentStep != null && currentStep > 1) {
				console.log('currentStep != null && currentStep > 1');
				await store.dispatch(fetchCompanyData());
		
				// this.unsubscribe_store = store.subscribe(() => {
				const companyType = store.getState()?.user?.companyData?.company?.type;
				console.log('companyType ', companyType);
				if (companyType === 'sole') {
					this.setSole();
				}
				// })
			}
			// End loading
			this.handleLoading();
		}, 3000);
	}

	componentWillUnmount() {
		// if (this.unsubscribe_store) {

		// 	this.unsubscribe_store();
		// }
		if (this.timer) {
			clearTimeout(this.timer);
		}
	}

	// Helper Methods
	renderStepForm(step) {
		switch (step) {
			case 1:
				return (
					<CompanyInfo
						solefunction={this.setSole.bind(this)}
						incrementfunction={this.incrementStep.bind(this)}
						{...this.props}
					/>
				);
			case 2:
				return <PersonalInfo incrementfunction={this.incrementStep.bind(this)} {...this.props} />;
			
			case 3:
				return <CompanyDetails incrementfunction={this.incrementStep.bind(this)} {...this.props} />;
			case 4:
				if (!this.state.sole) {
					return (
						<OwnershipInfo
							incrementfunction={this.incrementStep.bind(this)}
							setDotsVisibility={this.setDotsVisibility.bind(this)}
							{...this.props}
						/>
					);
				}
				return <LendingLicense incrementfunction={this.incrementStep.bind(this)} {...this.props} />;
			case 5:
				if (!this.state.sole) {
					return <LendingLicense incrementfunction={this.incrementStep.bind(this)} {...this.props} />;
				}
				return <LinkBank {...this.props} />;
			case 6:
				return <LinkBank {...this.props} />;
			default:
				return <PersonalInfo incrementfunction={this.incrementStep.bind(this)} {...this.props} />;
		}
	}

	incrementStep() {
		const newStep = this.state.currentStep + 1;
		this.setState({ currentStep: newStep });
	}

	setDotsVisibility(state) {
		this.setState({ dotVisibility: state });
	}

	setSole() {
		console.log('setSole ', true)
		this.setState({ sole: true });
	}

	// Render
	render() {
		const { loading, currentStep, sole, dotVisibility } = this.state;

		const stepNames = [
			"",
			"Company Information",
			"Personal Information",
			"Company Details",
			"Ownership Information",
			"Lending License",
			"Link Bank Account",
		];
		const soleStepNames = [
			"",
			"Company Information",
			"Personal Information",
			"Company Details",
			"Lending License",
			"Link Bank Account",
		];
		const stepList = [];
		const dotList = [];

		let numberOfSteps = 6;

		if (sole) {
			numberOfSteps = 5;
		}

		for (let i = 1; i <= numberOfSteps; i++) {
			if (i === currentStep) {
				stepList.push(
					<div key={i} className="step active-step">
						{i}. {sole ? soleStepNames[i] : stepNames[i]}
					</div>
				);
			} else {
				if (i < currentStep) {
					stepList.push(
						<div key={i} className="step">
							<img src={tick} alt="Step completed"></img> {sole ? soleStepNames[i] : stepNames[i]}
						</div>
					);
				} else {
					stepList.push(
						<div key={i} className="step">
							{i}. {sole ? soleStepNames[i] : stepNames[i]}
						</div>
					);
				}
			}
		}

		for (let i = 1; i <= numberOfSteps; i++) {
			if (i <= currentStep) {
				dotList.push(<img key={i} className="step-dot" src={activeDot} alt=""></img>);
			} else {
				dotList.push(<img key={i} className="step-dot" src={inactiveDot} alt=""></img>);
			}
		}

		// Check if loading
		if (loading) return <div>Loading...</div>;
		console.log('stepList ', stepList)
		console.log('currentStep ', currentStep)
		
		logFirebaseEvent('screen_view', {
			firebase_screen: window.location.pathname + window.location.search,
    		firebase_screen_class: window.location.pathname + window.location.search,
			currentStep: currentStep,
		});
		// Render
		return (
			<div className="signup-process-container">
				{/* Header */}
				<Header />
				{/* Header */}

				{/* Body */}
				<div className="d-flex">
					<div className="signup-form-sidebar pl-5 py-5">{stepList}</div>

					<div className="signup-form-container flex-grow-1">
						<div className="mb-3">{dotVisibility ? dotList : undefined}</div>
						<div>{this.renderStepForm(currentStep)}</div>
					</div>
				</div>
				{/* Body */}
			</div>
		);
	}
}

// Prop Types
SignUpProcess.propTypes = {
	prop: PropTypes.any,
	step: PropTypes.number,
};

function mapStateToProps(state) {
	return {
		companyData: state.user.companyData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchCompanyData: () => dispatch(fetchCompanyData()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpProcess);
